import React from 'react';
import { Link } from 'gatsby'

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper" className="no-bg">
      <header>
        <div className="inner">
          <h2>Page not found</h2>
          <p>
            Unfortunately we couldn't find whatever that was{' '}
            <span role="img" aria-label="think" className="think">&#129300;</span>
          </p>
          <Link to="/" className="button primary">
            Go back
          </Link>
        </div>
      </header>
    </section>
  </Layout>
);

export default IndexPage;
